import Navbar from "./Navbar";
import Mint from "./pages/Mint";
import DefiMint from "./pages/DefiMint";
import Home from "./pages/Home";
import StakeNFT from "./pages/StakeNFT";
import Footer from "./Footer";
import { Route, Routes } from "react-router-dom";
import TokenStake from "./pages/TokenStake";
import LockedStaking from "./pages/LockedStaking";
import Header from "./Header";
import NFTview from "./pages/NFTview";
import Tofu from "./pages/Tofu";
import Pool2 from "./pages/Pool2";
import Pool3 from "./pages/Pool3";
import GlobalState from "./pages/Component/globalstate";

function App() {
  return (
    <>
      <Header />
      <GlobalState />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Pool2" element={<Pool2 />} />
        <Route path="/Pool3" element={<Pool3 />} />

        <Route path="/Mint" element={<Mint />} />
        <Route path="/DefiMint" element={<DefiMint />} />
        <Route path="/tofu" element={<Tofu />} />
        <Route path="/ViewNFT" element={<NFTview />} />
        <Route path="/StakeNFT" element={<StakeNFT />} />
        <Route path="/LockedStaking" element={<LockedStaking />} />
        <Route path="/TokenStake" element={<TokenStake />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
