import { Link } from "react-router-dom";
import { BlockchainContext } from "./context/BlockchainContext";
import { useContext } from "react";

export default function Header() {
  const { approve, connectWallet, currentAccount, connectHelp } =
    useContext(BlockchainContext);

  const path = window.location.pathname;
  return (
    <div className="headerContainer">
      <div className="headerRowLeft">
        <div className="image">Sinu PowerStation</div>
      </div>
      <div className="headerRowRow">
        {/*  <div className="buttonBlue" onClick={connectWallet}>
          Tokens<div>{""}</div>
          <div className="buttonBlueText" id="balance">
            {" "}
          </div>
        </div>{" "}
        <div className="headerRow1">
          <div className="buttonBlue" onClick={connectWallet}>
            BNB<div>{""}</div>
            <div className="buttonBlueText" id="bnbBalance">
              {" "}
            </div>
          </div>{" "}
        </div> */}
        <div className="">{connectHelp}</div>
        <div className="headerRow2">
          <div className="buttonBlue" onClick={connectWallet}>
            {" "}
            {!currentAccount
              ? "Connect"
              : `${currentAccount.slice(0, 5)}...${currentAccount.slice(
                  currentAccount.length - 4
                )}`}{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
}
