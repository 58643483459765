import React, { useState } from "react";
import { useEffect, useRef } from "react";
//
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink";
import Web3 from "web3";
import { ethers } from "ethers";
//
import nftabi from "./nftabi.json";
import nftstakeabi from "./nftstakeabi.json";
import tokenabi from "./TOKENABI.json";
import GlobalState from "../pages/Component/globalstate";

export const BlockchainContext = React.createContext("");
var web3 = null;
var account = null;
var contract = null;
var provider = null;
var tokenContract = null;
let nftStakeContract = null;
let nftStakeContractRare = null;
let nftContractRare = null;
let nftContract = null;
var TokenStake = null;
//
//
var nftAddress = "0x13dBd9f301bE34a4c7c678BDE5358f6896BBa576"; // nft common
let nftContractRareAddress = "0x4c99A3a6423B718ebe018C949D5BF5aF50Ac79f5"; //nft rare

let nftStakeContractAddress = "0xB5319240A1DE9001dFe6D56090ED040aB16e83f4"; //common stake
let nftStakeContractRareAddress = "0xF47BF04ac71f505b96CD915993984a9a41e51FC3"; //rare

var tokenContractAddress = "0x5efe9c3e5b43580328104da18a091ce6a3d40651";

export const BlockchainProvider = ({ children }) => {
  const [currentAccount, setCurrentAccount] = useState("");
  // WC
  const [connectHelp, setConnectHelp] = useState(" ❌Connect your wallet❌");
  const [ApproveStakeState, setApproveStakeState] = useState("...");

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        rpc: {
          56: "https://bsc-dataseed1.defibit.io/",
        },
        network: "binance", // optional
        chainId: 56,
        infuraId: "2bb8830b4a274fda97f4743863b897bb",
        cacheProvider: true,
      },
    },
  };
  const web3Modal = new Web3Modal({
    network: "binance", // optional
    cacheProvider: true,
    providerOptions, // required
  });

  async function connectWallet() {
    const provider = await web3Modal.connect();
    let web3 = new Web3(provider);
    var accounts = await web3.eth.getAccounts();
    account = accounts[0];
    nftContract = new web3.eth.Contract(nftabi, nftAddress);
    nftContractRare = new web3.eth.Contract(nftabi, nftContractRareAddress);

    nftStakeContract = new web3.eth.Contract(
      nftstakeabi,
      nftStakeContractAddress
    );
    nftStakeContractRare = new web3.eth.Contract(
      nftstakeabi,
      nftStakeContractRareAddress
    );
    tokenContract = new web3.eth.Contract(tokenabi, tokenContractAddress);
    console.log(accounts[0]);

    setCurrentAccount(accounts[0]);

    // Data Locked Token Stake
  }

  // END of connect wallet function

  const checkifWalletIsConnected = async () => {
    try {
      const accounts = await provider.send("eth_accounts");
      if (accounts.length) {
        setCurrentAccount(accounts[0]);
      } else {
        console.log("No accounts found");
      }
    } catch (error) {
      console.log("error");
    }
  };

  const rewardsBalance = async () => {
    const balance = await tokenContract.methods
      .balanceOf("0xb5319240a1de9001dfe6d56090ed040ab16e83f4")
      .call();
    const balance2 = await tokenContract.methods
      .balanceOf("0xF47BF04ac71f505b96CD915993984a9a41e51FC3")
      .call();
    let sum = parseInt(balance) + parseInt(balance2);
    let total = (sum / 1000000).toFixed(2);
    return total;
  };

  // NFT Stake Functions
  async function approveNFTtoStake() {
    setStartRender(true);
    setTimeout(setStartRender, 5000, false);
    setstateMessage("APPORVING COMMON NFTs TO STAKE");
    nftContract.methods
      .setApprovalForAll(nftStakeContractAddress, true)
      .send({
        to: nftContract,
        from: account,
      })
      .once("error", (err) => {
        console.log(err);
        setstateMessage("ERROR TO MUCH POOOOWER - TRY AGAIN");
        setTimeout(setstateMessage, 2000, "");
        setTimeout(setStartRender, 2000, false);
        hiddenInit();
      })
      .then((receipt) => {
        setStartRender(true);
        setstateMessage("you can now stake your COMMON NFTs");
        setTimeout(setstateMessage, 2000, "");
        setTimeout(setStartRender, 2000, false);
        setTimeout(hiddenInit, 2000);
      });
  }
  async function approveNFTtoStakeRare() {
    setStartRender(true);
    setstateMessage("APPORVING RARE NFTs TO STAKE");

    nftContractRare.methods
      .setApprovalForAll(nftStakeContractRareAddress, true)
      .send({
        to: nftContractRare,
        from: account,
      })
      .once("error", (err) => {
        console.log(err);
        setstateMessage("ERROR TO MUCH POOOOWER - TRY AGAIN");
        setTimeout(setstateMessage, 2000, "");
        setTimeout(setStartRender, 2000, false);
        hiddenInit();
      })
      .then((receipt) => {
        console.log(receipt);
        setstateMessage("you can now stake your RARE NFTs");
        setTimeout(setstateMessage, 2000, "");
        setTimeout(setStartRender, 2000, false);
        setTimeout(hiddenInit, 2000);
      });
  }

  async function checkPendingRewardCommon() {
    var rawnfts = await nftStakeContract.methods.depositsOf(account).call();
    const arraynft = Array.from(rawnfts.map(Number));
    const tokenid = arraynft.filter(Number);
    var rwdArray = [];
    tokenid.forEach(async (id) => {
      var rawearn = await nftStakeContract.methods
        .calculateRewards(account, [id])
        .call();
      var array = Array.from(rawearn.map(Number));
      array.forEach(async (item) => {
        var earned = String(item).split(" ,")[0];
        var earnedrwd = earned;
        var rewardx = Number(earnedrwd).toFixed(4);
        var numrwd = Number(rewardx);
        rwdArray.push(numrwd);
      });
    });
    async function delay() {
      return new Promise((resolve) => setTimeout(resolve, 300));
    }
    async function delayedLog(item) {
      await delay();
      var sum = item.reduce((a, b) => a + b, 0);
      var formatsum = Number(sum).toFixed(4);
    }
    async function processArray(rwdArray) {
      for (const item of rwdArray) {
        await delayedLog(item);
      }
    }
    return processArray([rwdArray]).then((receipt) => {
      console.log(rwdArray / 1000000);
      setPendingRewardCommon(rwdArray / 1000000);
    });
  }
  async function checkPendingRewardRare() {
    var rawnfts = await nftStakeContractRare.methods.depositsOf(account).call();
    const arraynft = Array.from(rawnfts.map(Number));
    const tokenid = arraynft.filter(Number);
    var rwdArray = [];
    tokenid.forEach(async (id) => {
      var rawearn = await nftStakeContractRare.methods
        .calculateRewards(account, [id])
        .call();
      var array = Array.from(rawearn.map(Number));
      array.forEach(async (item) => {
        var earned = String(item).split(" ,")[0];
        var earnedrwd = earned;
        var rewardx = Number(earnedrwd).toFixed(4);
        var numrwd = Number(rewardx);
        rwdArray.push(numrwd);
      });
    });
    async function delay() {
      return new Promise((resolve) => setTimeout(resolve, 300));
    }
    async function delayedLog(item) {
      await delay();
      var sum = item.reduce((a, b) => a + b, 0);
      var formatsum = Number(sum).toFixed(4);
    }
    async function processArray(rwdArray) {
      for (const item of rwdArray) {
        await delayedLog(item);
      }
    }
    return processArray([rwdArray]).then((receipt) => {
      console.log(rwdArray / 1000000);
      setPendingRewardRare(rwdArray / 1000000);
    });
  }
  const checkRareBalance = async () => {
    let getnumber = await nftContractRare.methods.balanceOf(account).call();
    return getnumber;
  };
  const checkCommonBalance = async () => {
    let getnumber = await nftContract.methods.balanceOf(account).call();
    return getnumber;
  };

  async function checkUnstakedRare() {
    let Balance = await nftContractRare.methods.balanceOf(account).call();
    var nftArray = [];
    for (var i = 0; i < Balance; i++) {
      await nftContractRare.methods
        .tokenOfOwnerByIndex(account, i)
        .call()
        .then((id) => {
          nftArray.push(id);
        });
    }
    return nftArray.join(", ");
  }
  async function checkUnstakedCommon() {
    let Balance = await nftContract.methods.balanceOf(account).call();
    var nftArray = [];
    for (var i = 0; i < Balance; i++) {
      await nftContract.methods
        .tokenOfOwnerByIndex(account, i)
        .call()
        .then((id) => {
          nftArray.push(id);
        });
    }
    return nftArray.join(", ");
  }

  async function StakeAllCommon() {
    setStartRender(true);
    setTimeout(setStartRender, 5000, false);
    setstateMessage("STAKING ALL YOUR COMMON NFTS - CONFIRM TRANSACTION");

    let Balance = await nftContract.methods.balanceOf(account).call();
    var nftArray = [];
    for (var i = 0; i < Balance; i++) {
      await nftContract.methods
        .tokenOfOwnerByIndex(account, i)
        .call()

        .then((receipt) => {
          nftArray.push(receipt);
          let TokenidofUser = nftArray;
          nftStakeContract.methods
            .deposit(TokenidofUser)
            .send({
              to: nftStakeContract,
              from: account,
            })
            .once("error", (err) => {
              console.log(err);
              setstateMessage("ERROR TO MUCH POOOOWER - TRY AGAIN");
              setTimeout(setstateMessage, 2000, "");
              setTimeout(setStartRender, 2000, false);
              hiddenInit();
            })

            .then((receipt) => {
              console.log(receipt);
              setstateMessage("your COMMON NFTs are now staked");
              setTimeout(setstateMessage, 2000, "");
              setTimeout(setStartRender, 2000, false);
              setTimeout(hiddenInit, 2000);
            });
        });
    }
  }

  async function StakeAllRare() {
    setStartRender(true);
    setTimeout(setStartRender, 5000, false);
    setstateMessage("STAKING ALL YOUR RARE NFTS - CONFIRM TRANSACTION");

    let Balance = await nftContractRare.methods.balanceOf(account).call();
    var nftArray = [];
    for (var i = 0; i < Balance; i++) {
      await nftContractRare.methods
        .tokenOfOwnerByIndex(account, i)
        .call()
        .then((receipt) => {
          nftArray.push(receipt);
          let TokenidofUser = nftArray;
          nftStakeContractRare.methods
            .deposit(TokenidofUser)
            .send({
              to: nftStakeContractRare,
              from: account,
            })
            .once("error", (err) => {
              console.log(err);
              setstateMessage("ERROR TO MUCH POOOOWER - TRY AGAIN");
              setTimeout(setstateMessage, 2000, "");
              setTimeout(setStartRender, 2000, false);
              hiddenInit();
            })

            .then((receipt) => {
              setStartRender(true);
              setstateMessage("your Rare NFTs are now staked");
              setTimeout(setstateMessage, 2000, "");
              setTimeout(setStartRender, 2000, false);
              setTimeout(hiddenInit, 2000);
            });
        });
    }
  }

  async function unstakeallCommon() {
    setStartRender(true);
    setTimeout(setStartRender, 5000, false);
    setstateMessage("UNSTAKING COMMON NFTs - CONFIRM TRANSACTION");

    var rawnfts = await nftStakeContract.methods
      .depositsOf(account)
      .call()
      .then((receipt) => {
        let TID = receipt;

        nftStakeContract.methods
          .withdraw(TID)
          .send({
            to: nftStakeContract,
            from: account,
          })
          .once("error", (err) => {
            console.log(err);
            setstateMessage("ERROR TO MUCH POOOOWER - TRY AGAIN");
            setTimeout(setstateMessage, 2000, "");
            setTimeout(setStartRender, 2000, false);
            hiddenInit();
          })
          .then((receipt) => {
            setStartRender(true);
            setstateMessage("you got your COMMON NFTs back");
            setTimeout(setstateMessage, 2000, "");
            setTimeout(setStartRender, 2000, false);
            setTimeout(hiddenInit, 2000);
          });
      });
  }
  async function unstakeallRare() {
    setStartRender(true);
    setTimeout(setStartRender, 5000, false);
    setstateMessage("UNSTAKING RARE NFTs - CONFIRM TRANSACTION");

    var rawnfts = await nftStakeContractRare.methods
      .depositsOf(account)
      .call()
      .then((receipt) => {
        let TID = receipt;
        nftStakeContractRare.methods
          .withdraw(TID)
          .send({
            to: nftStakeContractRare,
            from: account,
          })
          .once("error", (err) => {
            console.log(err);
            setstateMessage("ERROR TO MUCH POOOOWER - TRY AGAIN");
            setTimeout(setstateMessage, 2000, "");
            setTimeout(setStartRender, 2000, false);
            hiddenInit();
          })
          .then((receipt) => {
            setStartRender(true);
            setstateMessage("you got your RARE NFTs back");
            setTimeout(setstateMessage, 2000, "");
            setTimeout(setStartRender, 2000, false);
            setTimeout(hiddenInit, 2000);
          });
      });
  }
  async function claimCommonRewards() {
    setStartRender(true);
    setTimeout(setStartRender, 5000, false);
    setstateMessage("Claiming Rewards - CONFIRM TRANSACTION");
    var rawnfts = await nftStakeContract.methods.depositsOf(account).call();
    const arraynft = Array.from(rawnfts.map(Number));
    const tokenid = arraynft.filter(Number);
    tokenid.forEach(async (id) => {
      await nftStakeContract.methods
        .claimRewards([id])
        .send({
          to: nftStakeContract,

          from: account,
        })
        .once("error", (err) => {
          console.log(err);
          setstateMessage("ERROR TO MUCH POOOOWER - TRY AGAIN");
          setTimeout(setstateMessage, 2000, "");
          setTimeout(setStartRender, 2000, false);
          hiddenInit();
        })
        .then((receipt) => {
          setStartRender(true);
          setstateMessage("Claim Successful gg wp");
          setTimeout(setstateMessage, 2000, "");
          setTimeout(setStartRender, 2000, false);
          setTimeout(hiddenInit, 2000);
        });
    });
  }
  async function claimRareRewards() {
    setStartRender(true);
    setTimeout(setStartRender, 5000, false);
    setstateMessage("Claiming Rewards - CONFIRM TRANSACTION");
    var rawnfts = await nftStakeContractRare.methods.depositsOf(account).call();
    const arraynft = Array.from(rawnfts.map(Number));
    const tokenid = arraynft.filter(Number);
    tokenid.forEach(async (id) => {
      await nftStakeContractRare.methods
        .claimRewards([id])
        .send({
          to: nftStakeContractRare,

          from: account,
        })
        .once("error", (err) => {
          console.log(err);
          setstateMessage("ERROR TO MUCH POOOOWER - TRY AGAIN");
          setTimeout(setstateMessage, 2000, "");
          setTimeout(setStartRender, 2000, false);
          hiddenInit();
        })
        .then((receipt) => {
          setStartRender(true);
          setstateMessage("Claim Successful gg wp");
          setTimeout(setstateMessage, 2000, "");
          setTimeout(setStartRender, 2000, false);
          setTimeout(hiddenInit, 2000);
        });
    });
  }

  const userCommonStaked = async () => {
    let getstakednfts = await nftStakeContract.methods
      .depositsOf(account)
      .call();
    return getstakednfts.join(", ");
  };
  const userRareStaked = async () => {
    let getstakednfts = await nftStakeContractRare.methods
      .depositsOf(account)
      .call();
    return getstakednfts.join(", ");
  };

  const userStakedPowerCommon = async () => {
    let getNFT = await nftStakeContract.methods.depositsOf(account).call();
    let length = getNFT.length;
    let sum = length * 50;
    console.log(sum);
    return sum;
  };
  const userStakedPowerRare = async () => {
    let getNFT = await nftStakeContractRare.methods.depositsOf(account).call();
    let length = getNFT.length;
    let sum = length * 100;
    console.log(sum);
    return sum;
  };

  const totalStakedPowerCommon = async () => {
    let getNFT = await nftStakeContract.methods.tokensStaked().call();
    let length = getNFT;
    let sum = length * 50;
    console.log(sum);
    return sum;
  };
  const totalStakedPowerRare = async () => {
    let getNFT = await nftStakeContractRare.methods.tokensStaked().call();
    let length = getNFT;
    let sum = length * 100;
    console.log(sum);
    return sum;
  };
  const totalNFTStaked = async () => {
    let getNFT = await nftStakeContract.methods.tokensStaked().call();
    let getNFTrare = await nftStakeContractRare.methods.tokensStaked().call();
    let total = parseInt(getNFT) + parseInt(getNFTrare);
    return total;
  };

  // vars, state etc for pool1
  const [contractRewardsBalance, setContractRewardsBalance] = useState("0");
  const [userRareStakedNFTs, setuserRareStakedNFTs] = useState("0");
  const [userCommonStakedNFTs, setuserCommonStakedNFTs] = useState("0");
  const [pendingRewardRare, setPendingRewardRare] = useState("0");
  const [pendingRewardCommon, setPendingRewardCommon] = useState("0");
  const [checkCommonBalanceNumber, setcheckCommonBalanceNumber] = useState("0");
  const [checkRareBalanceNumber, setcheckRareBalanceNumber] = useState("0");
  const [UnstakedCommon, setUnstakedCommon] = useState("0");
  const [UnstakedRare, setUnstakedRare] = useState(0);
  const [stakedUserPowerCommon, setstakedUserPowerCommon] = useState(0);
  const [stakedUserPowerRare, setstakedUserPowerRare] = useState(0);
  const [totalStakedPowerCommonNumber, settotalStakedPowerCommonNumber] =
    useState(0);
  const [totalStakedPowerRareNumber, settotalStakedPowerRareNumber] =
    useState(0);
  const [totalNFTStakedNumber, settotalNFTStakedNumber] = useState(0);
  // Conditional rendering states
  const [startRender, setStartRender] = useState(false);
  const [stateMessage, setstateMessage] = useState("");

  const hiddenInit = async () => {
    await connectWallet();
    setContractRewardsBalance(await rewardsBalance());
    setuserRareStakedNFTs(await userRareStaked());
    setuserCommonStakedNFTs(await userCommonStaked());
    setUnstakedCommon(await checkUnstakedCommon());
    setUnstakedRare(await checkUnstakedRare());
    setcheckRareBalanceNumber(await checkRareBalance());
    setcheckCommonBalanceNumber(await checkCommonBalance());
    setstakedUserPowerCommon(await userStakedPowerCommon());
    setstakedUserPowerRare(await userStakedPowerRare());
    settotalStakedPowerCommonNumber(await totalStakedPowerCommon());
    settotalStakedPowerRareNumber(await totalStakedPowerRare());
    settotalNFTStakedNumber(await totalNFTStaked());
    checkPendingRewardCommon();
    checkPendingRewardRare();

    /* initPool1();
      initPool2();
      initPool3(); */
  };
  useEffect(() => {
    hiddenInit();
  }, []);

  return (
    <BlockchainContext.Provider
      value={{
        contractRewardsBalance,
        stateMessage,
        setstateMessage,
        startRender,
        setStartRender,
        currentAccount,
        account,
        // data vars
        totalNFTStakedNumber,
        totalStakedPowerCommonNumber,
        stakedUserPowerCommon,
        UnstakedCommon,
        UnstakedRare,
        checkRareBalanceNumber,
        checkCommonBalanceNumber,
        pendingRewardCommon,
        userCommonStakedNFTs,
        userRareStakedNFTs,
        pendingRewardRare,
        stakedUserPowerRare,
        totalStakedPowerRareNumber,
        claimRareRewards,
        // functions

        unstakeallRare,
        StakeAllRare,
        approveNFTtoStakeRare,
        approveNFTtoStake,
        unstakeallCommon,
        claimCommonRewards,
        StakeAllCommon,
        connectWallet,
      }}
    >
      {children}
    </BlockchainContext.Provider>
  );
};
