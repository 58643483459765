import { BlockchainContext } from "../context/BlockchainContext";
import { useContext, useState, useRef, useEffect } from "react";
import CardImage from "../Images/logo1996.png";
export default function LockedStaking() {
  const {
    contractRewardsBalance,
    claimRareRewards,
    userRareStakedNFTs,
    unstakeallRare,
    StakeAllRare,
    approveNFTtoStakeRare,
    totalNFTStakedNumber,
    totalStakedPowerCommonNumber,
    connectWallet,
    unstakeallCommon,
    userCommonStakedNFTs,
    stakedUserPowerCommon,
    claimCommonRewards,
    StakeAllCommon,
    approveNFTtoStake,
    UnstakedCommon,
    UnstakedRare,
    checkRareBalanceNumber,
    checkCommonBalanceNumber,
    pendingRewardCommon,
    pendingRewardRare,
    stakedUserPowerRare,
    totalStakedPowerRareNumber,
  } = useContext(BlockchainContext);
  /* useEffect(() => {
    const init = async () => {
      checkBalance();
    };

    init();
  }, []); */

  return (
    <div className="centerGrid">
      <div className="headercontainerrow">
        <div className="headerbox">
          <div className="headerboxheader">Rewards Balance</div>
          <div className="headerboxinfo">{contractRewardsBalance}</div>
        </div>
        <div className="headerbox">
          <div className="headerboxheader">NFTs Staked</div>
          <div className="headerboxinfo">{totalNFTStakedNumber}</div>
        </div>
        <div className="headerbox">
          <div className="headerboxheader">Total Power Level</div>
          <div className="headerboxinfo">
            {" "}
            {totalStakedPowerCommonNumber + totalStakedPowerRareNumber}
          </div>
        </div>
      </div>{" "}
      <div className="col">
        <div className="bodyContainerBoxes">
          <div className="bodybox">
            <div className="bodyboxheader">⚡your total staked power⚡</div>
            <div className="bodyboxinfo">
              {stakedUserPowerCommon + stakedUserPowerRare}
            </div>
          </div>
          <div className="bodybox">
            <div className="bodyboxheader">🔌 your unstaked power 🔌</div>
            <div className="bodyboxinfo">
              {checkCommonBalanceNumber * 50 + checkRareBalanceNumber * 100}
            </div>
          </div>
        </div>{" "}
        <div className="bodyContainerBoxes">
          <div className="bodybox">
            <div className="bodyboxheader">⚡total common power⚡</div>
            <div className="bodyboxinfo">{totalStakedPowerCommonNumber}</div>
          </div>
          <div className="bodybox">
            <div className="bodyboxheader">⚡total Exclusive power⚡</div>
            <div className="bodyboxinfo">{totalStakedPowerRareNumber}</div>
          </div>
        </div>{" "}
        <div className="bodyContainerBoxes">
          <div className="bodyboxbig">
            <div className="bodyboxheader">
              ⚡Total Power across all common and Exclusive NFTs⚡{" "}
            </div>
            <div className="bodyboxinfo">
              {" "}
              {totalStakedPowerCommonNumber + totalStakedPowerRareNumber}
            </div>
          </div>{" "}
        </div>{" "}
        <div className="bodyContainerBoxes">
          <div className="bodybox">
            <div className="bodyboxheader">Pending Common Rewards</div>
            <div className="bodyboxinfo">{pendingRewardCommon}</div>
          </div>
          <div className="bodybox">
            <div className="bodyboxheader">Pending Exclusive Rewards</div>
            <div className="bodyboxinfo">{pendingRewardRare}</div>
          </div>
        </div>{" "}
        <div className="bodyContainerBoxes">
          <div className="bodybox">
            <div className="bodyboxheader">your unstaked common NFT IDs</div>
            <div className="bodyboxinfo">{UnstakedCommon}</div>
          </div>
          <div className="bodybox">
            <div className="bodyboxheader">
              {" "}
              your unstaked Exclusive NFT IDs
            </div>
            <div className="bodyboxinfo">{UnstakedRare}</div>
          </div>
        </div>{" "}
        <div className="bodyContainerBoxes">
          <div className="bodybox">
            <div className="bodyboxheader">your staked common NFTs</div>
            <div className="bodyboxinfo">{userCommonStakedNFTs}</div>
          </div>
          <div className="bodybox">
            <div className="bodyboxheader"> your staked Exclusive NFTs</div>
            <div className="bodyboxinfo">{userRareStakedNFTs}</div>
          </div>
        </div>{" "}
        <div className="bodyContainerBoxes">
          <div className="MobSection"> Common</div>
          <div className="ContainerButtons">
            <div className="bodyboxbutton" onClick={StakeAllCommon}>
              Stake All
            </div>
            <div className="bodyboxbutton" onClick={unstakeallCommon}>
              unstake all
            </div>
            <div className="bodyboxbutton" onClick={claimCommonRewards}>
              claim rewards
            </div>
          </div>{" "}
          <div className="MobSection"> Exclusive</div>
          <div className="ContainerButtons">
            <div className="bodyboxbutton" onClick={StakeAllRare}>
              Stake All
            </div>
            <div className="bodyboxbutton" onClick={unstakeallRare}>
              unstake all
            </div>
            <div className="bodyboxbutton" onClick={claimRareRewards}>
              claim rewards
            </div>
          </div>{" "}
        </div>{" "}
        <div className="bodyContainerBoxes">
          <div className="ContainerButtons">
            <button className="bodyboxbutton" onClick={approveNFTtoStake}>
              Approve Staking Common
            </button>
            <div className="bodyboxbutton" onClick={approveNFTtoStakeRare}>
              {" "}
              Approve Staking Exclusive
            </div>
          </div>{" "}
        </div>
      </div>{" "}
    </div>
  );
}
