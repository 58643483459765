import { BlockchainContext } from "../context/BlockchainContext";
import { useContext, useState, useRef } from "react";

export default function TokenStake() {
  const {
    useRef,
    onDeposit,
    depositRef,
    depositTokens,
    withdrawTokens,
    onWithDraw,
    withdrawRef,
    connectWallet,
    claimToken,
    approveStake,
  } = useContext(BlockchainContext);
  async function refreshPage() {
    {
      connectWallet();
    }
  }

  return (
    <div className="transform">
      <div className="boxbutton" onClick={approveStake}>
        Approve Staking
      </div>
      <div className="boxbutton" onClick={refreshPage}>
        Refresh Data
      </div>
      <div className="containerRow">
        <div className="box">
          APR %
          <div className="boxNumber" id="APR">
            0
          </div>
        </div>
      </div>
      <div className="containerRow">
        <div className="box">
          Tokens Staked
          <div className="boxNumber" id="StakedTokens">
            0
          </div>
        </div>
        <div className="box">Countdown</div>
        <div className="box">
          Your Balance
          <div className="boxNumber" id="balance">
            0
          </div>
        </div>
      </div>
      <div className="containerRow">
        <div className="box">
          User Deposited
          <div className="boxNumber" id="depositedTokens">
            0
          </div>
        </div>
        <div className="box">
          Pending Rewards
          <div className="boxNumber" id="pendingRewards">
            0
          </div>
        </div>
      </div>
      <div className="containerRow">
        <div className="boxCol">
          Deposit Tokens{" "}
          <form className="stats" onChange={onDeposit}>
            <input
              className="input"
              placeholder="INPUT VALUE"
              ref={depositRef}
              type="number"
            />
          </form>
          <button className="boxbutton" onClick={depositTokens} type="submit">
            DEPOSIT
          </button>
        </div>{" "}
        <div className="box">
          <button className="boxbutton" onClick={claimToken} type="submit">
            Claim
          </button>
        </div>
        <div className="boxCol">
          Withdraw Tokens{" "}
          <form className="stats" onChange={onWithDraw}>
            <input
              className="input"
              placeholder="INPUT VALUE"
              ref={withdrawRef}
              type="number"
            />
          </form>
          <button className="boxbutton" onClick={withdrawTokens} type="submit">
            Withdraw
          </button>
        </div>
      </div>{" "}
      <div className="nullBox">
        <div id="APR"></div>
        <div id="StakedTokens"></div>
        <div id="balance"></div>
        <div id="pendingRewards"></div>
        <div id="depositedTokens"></div>
        <div id="earned"></div>
        <div id="getstakednfts"></div>
        <div id="costofmint"></div>
        <div id="totalSupply"></div>
        <div id="maxSupply"></div>
        <div id="totalnftstaked"></div>
        <div id="earned"></div>
        <div id="userLocked"></div>
        <div id="lockedPendingRewards"></div>
        <div id="holderUnlockTime"></div>
        <div id="lockedAPR"></div> <div id="totalLockedStake"></div>{" "}
        <div id="exitPenaltyPerc"></div> <div id="LockedTokenBalance"></div>{" "}
      </div>{" "}
    </div>
  );
}
