import { BlockchainContext } from "../context/BlockchainContext";
import { useContext, useState, useRef, useEffect } from "react";
import CardImage from "../Images/logo1996.png";
export default function LockedStaking() {
  const {
    initPool1,
    initPool2,
    initPool3,
    RemainingDays3,
    UnlockDatePool3,
    PendingRewardsPool3,
    UserLockedTokensPool3,
    ContractTokenBalancePool3,
    TotalLockedStakePool3,
    LockedAPRPool3,
    EarlyExitTaxPool3,
    getPendingRewards,
    getContractTokenBalance,
    getTotalLockedStake,
    getLockedAPR,
    getEarlyExitTax,
    getUserLockedTokens,
    getUnlockData,
    claimPendingPool3,
    approveLockedStakePool3,
    depositLockedTokensPool3,
    withdrawLockedTokensPool3,
    emergencyWithdrawPool3,
    onDeposit,
    depositRef,
    connectWallet,
  } = useContext(BlockchainContext);

  useEffect(() => {
    const init = async () => {
      initPool3();
    };

    init();
  }, []);

  return (
    <div className="centerGrid">
      <div className="PoolCardBG">
        <div className="PoolCardHeader">
          <img src={CardImage} className="imageCard"></img>
        </div>
        <div className="PoolCardButton" onClick={approveLockedStakePool3}>
          Approve
        </div>
        <div className="PoolCardBox">
          <div>Remaining Reward Days</div>
          <div>{RemainingDays3}</div>
        </div>
        <div className="PoolCardBox">
          <div>APY:</div>
          <div>{LockedAPRPool3}%</div>
        </div>
        <div className="PoolCardBox">
          <div>Contract Rewards Balance:</div>
          <div>
            {Number(ContractTokenBalancePool3 - TotalLockedStakePool3).toFixed(
              2
            )}
          </div>
        </div>
        <div className="PoolCardBox">
          {" "}
          <div>Total Staked:</div>
          <div>{TotalLockedStakePool3}</div>
        </div>{" "}
        <div className="PoolCardBox">
          <div>Early Withdraw Tax:</div> <div>{EarlyExitTaxPool3}%</div>
        </div>
        <div className="PoolCardBox">
          <div className="gridboxleft">Your Locked Tokens: </div>{" "}
          <div className="">{UserLockedTokensPool3} </div>
        </div>
        <div className="PoolCardBox">
          {" "}
          <div className="gridboxleft">Pending Rewards: </div>{" "}
          <div>{PendingRewardsPool3}</div>{" "}
        </div>{" "}
        {/* 
        <form className="gridbox" onChange={onWithDraw}>
          <input
            className="PoolCardButton"
            placeholder="INPUT VALUE"
            ref={withdrawRef}
            type="number"
          />
          <button
            className="PoolCardButton"
            onClick={withdrawLockedRewards}
            type="submit"
          >
            Claim
          </button>
        </form>*/}
        <div className="PoolCardBox">
          <form className="gridboxleft" onChange={onDeposit}>
            <input
              className="gridinput"
              placeholder="INPUT VALUE"
              ref={depositRef}
              type="number"
            />{" "}
          </form>{" "}
          <div className="flex">
            <button
              className="PoolCardButton"
              onClick={depositLockedTokensPool3}
              type="submit"
            >
              DEPOSIT
            </button>{" "}
            <button className="PoolCardButton" onClick={claimPendingPool3}>
              Claim
            </button>{" "}
          </div>
        </div>
        <div className="PoolCardBox">
          <div className="gridboxleft">Emergency Withdraw</div>{" "}
          <div className="PoolCardButton" onClick={emergencyWithdrawPool3}>
            withdraw
          </div>{" "}
        </div>
        <div className="PoolCardBox">
          {" "}
          <div className="gridboxleft">Lock Period is over</div>{" "}
          <button
            className="PoolCardButton"
            onClick={withdrawLockedTokensPool3}
            type="submit"
          >
            Claim
          </button>{" "}
        </div>{" "}
        <div className="PoolCardBox">
          <div className="gridboxleft">Unlock Date </div> {UnlockDatePool3}
        </div>
      </div>{" "}
      <div className="nullBox">
        <div id="earned"></div>
      </div>{" "}
    </div>
  );
}
