import { BlockchainContext } from "../../context/BlockchainContext";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { render } from "@testing-library/react";
import videoplayback from "./videoplayback.mp4";
export default function GlobalState() {
  const { startRender, setStartRender, stateMessage, setStateMessage } =
    useContext(BlockchainContext);
  let startVideo = false;
  if (startRender) {
    return <div className="GlobalState">{stateMessage} </div>;
  }
  if (startVideo) {
    return (
      <div className="video">
        <video loop autoPlay controls muted src={videoplayback}></video>
      </div>
    );
  }
}
/* 



*/
