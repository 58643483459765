import { BlockchainContext } from "../context/BlockchainContext";
import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function Mint() {
  const {
    claimRewards,
    useState,
    mintAmount,
    incrementMintAmount,
    decrementMintAmount,
    mintNFT,
    accounts,
    mintCost,
    maxMint,
    totalSupply,
    mintNFTone,
    maxSupply,
    connectWallet,
    currentAccount,
  } = useContext(BlockchainContext);

  async function refreshPage() {
    {
      connectWallet();
    }
  }
  return (
    <div className="centerGrid">
      <div className="grid">
        <div className="boxRow">
          <div className="box">
            NFT Supply
            <div className="gridnumber" id="maxSupply">
              <div className="logo"></div>
            </div>
          </div>
          <div className="box">
            NFT Cost
            <div className="gridnumber" id="costofmint">
              <div className="logo"></div>
            </div>
          </div>{" "}
        </div>{" "}
        <div className="boxRow">
          <div className="box">
            NFTs Minted
            <div className="gridnumber" id="totalSupply">
              <div className="logo"></div>
            </div>
          </div>{" "}
          <div className="box">
            NFT Balance
            <div className="gridnumber" id="balanceOfNFT">
              <div className="logo"></div>
            </div>
          </div>
        </div>{" "}
        <div className="boxRow">
          <div className="box">
            BNB earned
            <div className="gridnumber" id="BNBpending">
              <div className="logo"></div>
            </div>
          </div>{" "}
          <div className="box">
            Claim BNB
            <div className="gridnumber" id="balanceOfNFT">
              <div className="boxClaim" onClick={claimRewards}>
                Claim
              </div>{" "}
            </div>
          </div>
        </div>{" "}
        <div className="boxRow">
          <div className="increment" onClick={decrementMintAmount}>
            -
          </div>
          <div className="increment">{mintAmount}</div>
          <div className="increment" onClick={incrementMintAmount}>
            +
          </div>{" "}
        </div>
        <div className="boxRow">
          <div className="boxMint" onClick={mintNFT}>
            MINT
          </div>{" "}
        </div>
        <div className="nullBox">
          <div className="nullBox">
            <div id="APR"></div>
            <div id="StakedTokens"></div>
            <div id="balance"></div>
            <div id="pendingRewards"></div>
            <div id="depositedTokens"></div>
            <div id="earned"></div>
            <div id="getstakednfts"></div>
            <div id="costofmint"></div>
            <div id="totalSupply"></div>
            <div id="maxSupply"></div>
            <div id="totalnftstaked"></div>
            <div id="earned"></div>
            <div id="userLocked"></div>
            <div id="lockedPendingRewards"></div>
            <div id="holderUnlockTime"></div>
            <div id="lockedAPR"></div> <div id="totalLockedStake"></div>{" "}
            <div id="exitPenaltyPerc"></div> <div id="LockedTokenBalance"></div>{" "}
            <div id="APR"></div>
            <div id="StakedTokens"></div>
            <div id="balance"></div>
            <div id="pendingRewards"></div>
            <div id="depositedTokens"></div>
            <div id="earned"></div>
            <div id="getstakednfts"></div>
            <div id="costofmint"></div>
            <div id="totalSupply"></div>
            <div id="maxSupply"></div>
            <div id="totalnftstaked"></div>
            <div id="earned"></div>
            <div id="userLocked"></div>
            <div id="rewardBalance"></div>
            <div id="lockedPendingRewards"></div>
            <div id="holderUnlockTime"></div>
            <div id="lockedAPR"></div> <div id="totalLockedStake"></div>{" "}
            <div id="exitPenaltyPerc"></div> <div id="LockedTokenBalance"></div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    </div>
  );
}
