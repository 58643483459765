import { BlockchainContext } from "../context/BlockchainContext";
import { useContext, useState, useRef, useEffect } from "react";

export default function Tofu() {
  const {
    account,
    currentAccount,
    useRef,
    onDeposit,
    depositRef,
    depositTokens,
    withdrawTokens,
    onWithDraw,
    withdrawRef,
    connectWallet,
    claimToken,
    approveLockedStake,
    depositLockedTokens,
    withdrawLockedTokens,
    emergencyWithdraw,
    withdrawLockedRewards,
  } = useContext(BlockchainContext);
  async function refreshPage() {
    {
      connectWallet();
    }
  }
  useEffect(() => {
    connectWallet();
  }, []);

  let tofuUser = `https://tofunft.com/user/${account}/items/in-wallet`;

  return (
    <div className="centerGrid">
      <div className="grid">
        <iframe
          className="iframe"
          src={tofuUser}
          width={1000}
          height={700}
          sandbox="allow-scripts allow-modal"
          loading="lazy"
          title="Custom title"
        ></iframe>
      </div>
      <div className="nullBox">
        <div id="balanceOfNFT"></div>
        <div id="balanceBNB"></div>
        <div id="APR"></div>
        <div id="StakedTokens"></div>
        <div id="balance"></div>
        <div id="pendingRewards"></div>
        <div id="depositedTokens"></div>
        <div id="earned"></div>
        <div id="getstakednfts"></div>
        <div id="costofmint"></div>
        <div id="totalSupply"></div>
        <div id="maxSupply"></div>
        <div id="totalnftstaked"></div>
        <div id="earned"></div>
        <div id="userLocked"></div>
        <div id="lockedPendingRewards"></div>
        <div id="holderUnlockTime"></div>
        <div id="lockedAPR"></div> <div id="totalLockedStake"></div>{" "}
        <div id="exitPenaltyPerc"></div> <div id="LockedTokenBalance"></div>{" "}
        <div id="APR"></div>
        <div id="StakedTokens"></div>
        <div id="balance"></div>
        <div id="pendingRewards"></div>
        <div id="depositedTokens"></div>
        <div id="earned"></div>
        <div id="getstakednfts"></div>
        <div id="costofmint"></div>
        <div id="totalSupply"></div>
        <div id="maxSupply"></div>
        <div id="totalnftstaked"></div>
        <div id="earned"></div>
        <div id="userLocked"></div>
        <div id="rewardBalance"></div>
        <div id="lockedPendingRewards"></div>
        <div id="holderUnlockTime"></div>
        <div id="lockedAPR"></div> <div id="totalLockedStake"></div>{" "}
        <div id="exitPenaltyPerc"></div> <div id="LockedTokenBalance"></div>{" "}
      </div>{" "}
    </div>
  );
}
