import { BlockchainContext } from "../context/BlockchainContext";
import { useContext, useState, useRef, useEffect } from "react";
import CardImage from "../Images/logo1996.png";
export default function LockedStaking() {
  const {
    getPendingRewards,
    getContractTokenBalance,
    getTotalLockedStake,
    getLockedAPR,
    getEarlyExitTax,
    getUserLockedTokens,
    getUnlockData,
    claimPending,
    onDeposit,
    depositRef,
    connectWallet,
    approveLockedStake,
    depositLockedTokens,
    withdrawLockedTokens,
    emergencyWithdraw,
    ApproveStakeState,
  } = useContext(BlockchainContext);

  const [UnlockDate, setUnlockDate] = useState(0);
  const [PendingRewards, setPendingRewards] = useState(0);
  const [UserLockedTokens, setUserLockedTokens] = useState(0);
  const [ContractTokenBalance, setContractTokenBalance] = useState(0);
  const [TotalLockedStake, setTotalLockedStake] = useState(0);
  const [LockedAPR, setLockedAPR] = useState(0);
  const [EarlyExitTax, setEarlyExitTax] = useState(0);
  useEffect(() => {
    const init = async () => {
      await connectWallet();
      setUnlockDate(await getUnlockData());
      setPendingRewards(await getPendingRewards());
      setLockedAPR(await getLockedAPR());
      setContractTokenBalance(await getContractTokenBalance());
      setTotalLockedStake(await getTotalLockedStake());
      setEarlyExitTax(await getEarlyExitTax());
      setUserLockedTokens(await getUserLockedTokens());
    };
  }, []);

  return (
    <div className="centerGrid">
      <div className="PoolCardBG">
        <div className="PoolCardHeader">
          <img src={CardImage} className="imageCard"></img>
        </div>
        <div>Status: {ApproveStakeState}</div>
        <div className="PoolCardButton" onClick={approveLockedStake}>
          Approve
        </div>
        <div className="PoolCardBox">
          <div>APY:</div>
          <div>{LockedAPR}%</div>
        </div>
        <div className="PoolCardBox">
          <div>Contract Rewards Balance:</div>
          <div>
            {Number(ContractTokenBalance - TotalLockedStake).toFixed(2)}
          </div>
        </div>
        <div className="PoolCardBox">
          {" "}
          <div>Total Staked:</div>
          <div>{TotalLockedStake}</div>
        </div>{" "}
        <div className="PoolCardBox">
          <div>Early Withdraw Tax:</div> <div>{EarlyExitTax}%</div>
        </div>
        <div className="PoolCardBox">
          <div className="gridboxleft">Your Locked Tokens: </div>{" "}
          <div className="">{UserLockedTokens} </div>
        </div>
        <div className="PoolCardBox">
          {" "}
          <div className="gridboxleft">Pending Rewards: </div>{" "}
          <div>{PendingRewards}</div>{" "}
        </div>{" "}
        {/* 
        <form className="gridbox" onChange={onWithDraw}>
          <input
            className="PoolCardButton"
            placeholder="INPUT VALUE"
            ref={withdrawRef}
            type="number"
          />
          <button
            className="PoolCardButton"
            onClick={withdrawLockedRewards}
            type="submit"
          >
            Claim
          </button>
        </form>*/}
        <div className="PoolCardBox">
          <form className="gridboxleft" onChange={onDeposit}>
            <input
              className="gridinput"
              placeholder="INPUT VALUE"
              ref={depositRef}
              type="number"
            />{" "}
          </form>{" "}
          <div className="">
            <button
              className="PoolCardButton"
              onClick={depositLockedTokens}
              type="submit"
            >
              DEPOSIT
            </button>{" "}
            <button className="PoolCardButton" onClick={claimPending}>
              Claim Rewards
            </button>{" "}
          </div>
        </div>
        <div className="PoolCardBox">
          <div className="gridboxleft">Emergency Withdraw</div>{" "}
          <div className="PoolCardButton" onClick={emergencyWithdraw}>
            withdraw
          </div>{" "}
        </div>
        <div className="PoolCardBox">
          {" "}
          <div className="gridboxleft">Lock Period is over</div>{" "}
          <button
            className="PoolCardButton"
            onClick={withdrawLockedTokens}
            type="submit"
          >
            Claim
          </button>{" "}
        </div>{" "}
        <div className="PoolCardBox">
          <div className="gridboxleft">Your Unlock Date </div> {UnlockDate}
        </div>
      </div>{" "}
      <div className="nullBox">
        <div id="LockedTokenBalancePool2"></div>
        <div id="LockedTokenBalancePool3"></div>
        <div id="holderUnlockTimePool3"></div>
        <div id="totalLockedStakePool3"></div>
        <div id="LockedTokenBalancePool3"></div>
        <div id="lockedAPRPool3"></div>
        <div id="rewardBalancePool3"></div>
        <div id="exitPenaltyPercPool3"></div>
        <div id="userLockedPool3"></div>
        <div id="lockedPendingRewardsPool3"></div>
        <div id="LockedTokenBalancePool3"></div>
        <div id="holderUnlockTimePool2"></div>
        <div id="totalLockedStakePool2"></div>
        <div id="LockedTokenBalancePool2"></div>
        <div id="lockedAPRPool2"></div>
        <div id="rewardBalancePool2"></div>
        <div id="exitPenaltyPercPool2"></div>
        <div id="userLockedPool2"></div>
        <div id="lockedPendingRewardsPool2"></div>
        <div id="BNBpending"></div>
        <div id="APR"></div>
        <div id="StakedTokens"></div>
        <div id="balance"></div>
        <div id="pendingRewards"></div>
        <div id="depositedTokens"></div>
        <div id="earned"></div>
        <div id="getstakednfts"></div>
        <div id="costofmint"></div>
        <div id="totalSupply"></div>
        <div id="maxSupply"></div>
        <div id="totalnftstaked"></div>
        <div id="earned"></div>
        <div id="userLocked"></div>
        <div id="lockedPendingRewards"></div>
        <div id="holderUnlockTime"></div>
        <div id="lockedAPR"></div> <div id="totalLockedStake"></div>{" "}
        <div id="exitPenaltyPerc"></div> <div id="LockedTokenBalance"></div>{" "}
        <div className="nullBox">
          <div id="APR"></div>
          <div id="StakedTokens"></div>
          <div id="balance"></div>
          <div id="pendingRewards"></div>
          <div id="depositedTokens"></div>
          <div id="earned"></div>
          <div id="getstakednfts"></div>
          <div id="costofmint"></div>
          <div id="totalSupply"></div>
          <div id="maxSupply"></div>
          <div id="totalnftstaked"></div>
          <div id="earned"></div>
          <div id="userLocked"></div>
          <div id="rewardBalance"></div>
          <div id="balanceOfNFT"></div>
          <div id="lockedPendingRewards"></div>
          <div id="holderUnlockTime"></div>
          <div id="lockedAPR"></div> <div id="totalLockedStake"></div>{" "}
          <div id="exitPenaltyPerc"></div> <div id="LockedTokenBalance"></div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
}
