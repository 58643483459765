import { BlockchainContext } from "../context/BlockchainContext";
import { useContext, useState, useRef, useEffect } from "react";
import CardImage from "../Images/logo1996.png";
export default function LockedStaking() {
  const {
    RemainingDays2,
    initPool1,
    initPool2,
    initPool3,

    UnlockDatePool2,
    PendingRewardsPool2,
    UserLockedTokensPool2,
    ContractTokenBalancePool2,
    TotalLockedStakePool2,
    LockedAPRPool2,
    EarlyExitTaxPool2,
    getPendingRewards,
    getContractTokenBalance,
    getTotalLockedStake,
    getLockedAPR,
    getEarlyExitTax,
    getUserLockedTokens,
    getUnlockData,
    claimPendingPool2,
    approveLockedStakePool2,
    depositLockedTokensPool2,
    withdrawLockedTokensPool2,
    emergencyWithdrawPool2,
    onDeposit,
    depositRef,
    connectWallet,
  } = useContext(BlockchainContext);

  useEffect(() => {
    const init = async () => {
      initPool2();
    };

    init();
  }, []);

  return (
    <div className="centerGrid">
      <div className="PoolCardBG">
        <div className="PoolCardHeader">
          <img src={CardImage} className="imageCard"></img>
        </div>
        <div className="PoolCardButton" onClick={approveLockedStakePool2}>
          Approve
        </div>
        <div className="PoolCardBox">
          <div>Remaining Reward Days</div>
          <div>{RemainingDays2}</div>
        </div>
        <div className="PoolCardBox">
          <div>APY:</div>
          <div>{LockedAPRPool2}%</div>
        </div>
        <div className="PoolCardBox">
          <div>Contract Rewards Balance:</div>
          <div>
            {Number(ContractTokenBalancePool2 - TotalLockedStakePool2).toFixed(
              2
            )}
          </div>
        </div>
        <div className="PoolCardBox">
          {" "}
          <div>Total Staked:</div>
          <div>{TotalLockedStakePool2}</div>
        </div>{" "}
        <div className="PoolCardBox">
          <div>Early Withdraw Tax:</div> <div>{EarlyExitTaxPool2}%</div>
        </div>
        <div className="PoolCardBox">
          <div className="gridboxleft">Your Locked Tokens: </div>{" "}
          <div className="">{UserLockedTokensPool2} </div>
        </div>
        <div className="PoolCardBox">
          {" "}
          <div className="gridboxleft">Pending Rewards: </div>{" "}
          <div>{PendingRewardsPool2}</div>{" "}
        </div>{" "}
        {/* 
        <form className="gridbox" onChange={onWithDraw}>
          <input
            className="PoolCardButton"
            placeholder="INPUT VALUE"
            ref={withdrawRef}
            type="number"
          />
          <button
            className="PoolCardButton"
            onClick={withdrawLockedRewards}
            type="submit"
          >
            Claim
          </button>
        </form>*/}
        <div className="PoolCardBox">
          <form className="gridboxleft" onChange={onDeposit}>
            <input
              className="gridinput"
              placeholder="INPUT VALUE"
              ref={depositRef}
              type="number"
            />{" "}
          </form>{" "}
          <div className="flex">
            <button
              className="PoolCardButton"
              onClick={depositLockedTokensPool2}
              type="submit"
            >
              DEPOSIT
            </button>{" "}
            <button className="PoolCardButton" onClick={claimPendingPool2}>
              Claim
            </button>{" "}
          </div>
        </div>
        <div className="PoolCardBox">
          <div className="gridboxleft">Emergency Withdraw</div>{" "}
          <div className="PoolCardButton" onClick={emergencyWithdrawPool2}>
            withdraw
          </div>{" "}
        </div>
        <div className="PoolCardBox">
          {" "}
          <div className="gridboxleft">Lock Period is over</div>{" "}
          <button
            className="PoolCardButton"
            onClick={withdrawLockedTokensPool2}
            type="submit"
          >
            Claim
          </button>{" "}
        </div>{" "}
        <div className="PoolCardBox">
          <div className="gridboxleft">Unlock Date </div> {UnlockDatePool2}
        </div>
      </div>{" "}
      <div className="nullBox">
        <div id="earned"></div>
      </div>{" "}
    </div>
  );
}
