import { Link } from "react-router-dom";
import { BlockchainContext } from "./context/BlockchainContext";
import { useContext } from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { GiWorld } from "react-icons/gi";
import { GrTwitter } from "react-icons/gr";
import { SiBinance } from "react-icons/si";
import { BsFillBarChartLineFill } from "react-icons/bs";
import { FcInspection } from "react-icons/fc";
import { SiAdobeaudition } from "react-icons/si";

export default function Footer() {
  const { connectWallet, currentAccount } = useContext(BlockchainContext);

  const path = window.location.pathname;
  return (
    <div className="footer">
      <a className="devButton" href="http://shepherdinu.tech">
        <GiWorld /> Website
      </a>
      <a className="devButton" href="https://t.me/shepherdinuportal">
        <FaTelegramPlane /> Telegram
      </a>
      {/* 
      <a
        className="devButton"
        href="https://twitter.com/shepherdinubsc?t=1CRIYTnV12kjTC0XDtLDSA&s=09"
      >
        <GrTwitter /> Twitter
      </a>
      <a
        className="devButton"
        href="https://poocoin.app/tokens/0x5efe9c3e5b43580328104da18a091ce6a3d40651"
      >
        <SiBinance /> Contract
      </a>
      <a
        className="devButton"
        href="https://poocoin.app/tokens/0x5efe9c3e5b43580328104da18a091ce6a3d40651"
      >
        <BsFillBarChartLineFill /> Chart
      </a>*/}
      <a className="devButton" href="https://t.me/DegenDapps">
        <FcInspection /> Dapp Dev
      </a>
    </div>
  );
}
